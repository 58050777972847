@import "../variables";

.encabezado-biografia {
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
    p {
        display: inline-block;
        border-bottom: 1px solid $color-gris;
        font-family: $bookman !important;
        font-size: 0.9em !important;
        font-weight: 500;
        padding-bottom: 0.25rem;
    }
    svg {
        fill: $colorInterfaz;
        transform: rotate(90deg);
    }
}

.encabezado-biografia-activa {
    flex-direction: column;
}

.svg-biografia {
    max-width: 13px;
}

.flecha-arriba {
    transform: rotate(-90deg);
}

.flecha-abajo {
    transform: rotate(90deg);
}

.info-biografia {
    .texto-contenido {
        margin-top: 3rem;
        overflow-y: auto;
        padding-right: 1.5rem;
    }
    .content-buttons--change-font-size {
        left: 0;
        top: 4.5rem;
    }
}

.prologos{
    .contenido-biografia{
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .info-biografia {
        .texto-contenido {
            max-height: 150px;
        }
    }
}
