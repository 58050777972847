@import "./variables.scss";

.btn-continuar {
    font-family: $futura;
    font-size: 1.4em;
    padding: 1.2em 0;
    text-transform: uppercase;
    a {
        background: url("../assets/img/flecha_creditos.svg") center right / contain no-repeat;
        color: $colorInterfaz;
        font-family: $futura;
        font-weight: bold;
        line-height: 0.83em;
        padding-right: 20px;
        position: relative;
    }
}

.code-isbn {
    text-align: left;
}

.creditos {
    h2 {
        color: $prologo;
    }
}

.fondo-creditos {
    background-attachment: fixed;
    background-image: url("../assets/img/mapa_continentes.png"), url("../assets/img/fondo_creditos.jpg");
    background-position: center, center;
    background-repeat: no-repeat, repeat;
    background-size: contain, cover;
}

.info-colaboradores {
    p {
        &:first-child {
            font-weight: 900;
            margin-bottom: 1rem;
        }
    }
}

.info-creditos {
    overflow: none;
    div {
        &:not(:first-child) {
            margin-top: 2rem;
        }
    }
    h3 {
        font-family: $berkeley;
        font-weight: 900;
        margin: 0;
    }
}

.logo {
    margin-top: 20px;
    max-width: 100px;
}
//responsive
@media only screen and (min-height: 605px) {
    .logo {
        max-width: 180px !important;
    }
}

@media only screen and (min-width: 992px) {
    .code-isbn {
        text-align: right;
    }
    .btn-continuar {
        bottom: 0;
        right: 0;
    }
    .info-creditos {
        overflow: auto;
    }
}