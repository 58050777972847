//Fuente Futura
@font-face {
  font-family: "Futura Std";
  font-weight: 500;
  src: url("../fonts/futura_std_medium.otf") format("opentype");
}
@font-face {
  font-family: "Futura Std";
  font-weight: 600;
  src: url("../fonts/futura_std_condensed_bold.otf") format("opentype");
}

//Fuente Garamond
@font-face {
  font-family: "Garamond";
  font-weight: 400;
  src: url("../fonts/a_garamond_pro_regular.otf") format("opentype");
}
@font-face {
  font-family: "Garamond";
  font-weight: 600;
  src: url("../fonts/a_garamond_pro_semibold.otf") format("opentype");
}
@font-face {
  font-family: "Garamond";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/a_garamond_pro_italic.otf") format("opentype");
}

//Fuente Berkeley
@font-face {
  font-family: "Berkeley Std";
  font-weight: 700;
  src: url("../fonts/berkeley_std_bold.otf") format("opentype");
}
@font-face {
  font-family: "Berkeley Std";
  font-weight: 900;
  src: url("../fonts/berkeley_std_black.otf") format("opentype");
}
@font-face {
  font-family: "Berkeley Std";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/berkeley_std_black_italic.otf") format("opentype");
}

//Fuente Berkeley
@font-face {
  font-family: "Bookman Std";
  font-weight: 300;
  src: url("../fonts/bookman_std_light.otf") format("opentype");
}
@font-face {
  font-family: "Bookman Std";
  font-weight: 500;
  src: url("../fonts/bookman_std_medium.otf") format("opentype");
}
@font-face {
  font-family: "Bookman Std";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/bookman_std_medium_italic.otf") format("opentype");
}

//Colores
$colorInterfaz: #2b4d9c;
$africa: #f3bd7f;
$america: #b8cfc9;
$asia: #a2bdd2;
$europa: #fda1a9;
$oceania: #b49cbe;
$prologo: #e2656a;

$color-gris: #71777a;

$colorTextos: #323232;

$futura: "Futura Std";
$garamond: "Garamond";
$berkeley: "Berkeley Std";
$bookman: "Bookman Std";

//Clases
.scrollbar {
  overflow-y: auto ;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #e6e6e6;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $colorInterfaz;
  }
}
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 526px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px,
  // Extra large screen / wide desktop
  xxl: 1440px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px,
) !default;
