@import "../variables.scss";

.textosMapa {
  line-height: 0.8em;
  text-align: center;
  text-transform: uppercase;
  p {
      border-bottom: 2px solid $color-gris;
      font-family: $futura;
      font-weight: 700;
      padding-bottom: 0.4rem;
      padding-top: 0.7rem;
  }
  span {
      margin: 0;
      text-decoration: none;
  }
}
.nombre-continente {
    font-size: 2em;
    a {
        text-align: center;
    }
    p{
        border-width: 3px !important;
        display: table;
        margin: 0 auto;
    }
    @extend .textosMapa;
}

.item-continente-movil {
    height: 18vh;
    font-size: 2em;
    @extend .textosMapa;
}

@media only screen and (min-width: 992px) {
    .componente-menu-continentes {
        height: 95vh;
        overflow: hidden;
    }
}
