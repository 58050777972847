
/*--------------------------------
             preloader
---------------------------------*/
.preload {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0 !important;
    z-index: 999;
    background: black;
    .container {
        width: 100%;
        height: 100%;
        position: relative;
    }
    p {
        color: white;
        text-align: center;
        position: absolute;
        margin: auto;
        top: 0;
        width: 100%;
        height: 20px;
        bottom: 0;
    }
}

.preload-images {
    display: none;
}

@keyframes FadeIn {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

.fadeIn {
    animation: FadeIn 0.4s ease-in forwards;
    overflow: hidden;
    .container {
        display: none;
    }
}
