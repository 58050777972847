@import "../variables";

.componente-contenido {
    overflow: hidden;
    p {
        font-size: 0.8em;
        font-family: $garamond;
    }
}

.nombre-ilustrador {
    p {
        font-family: $garamond;
        font-size: 0.8em;
        font-weight: 400;
        margin: 0 !important;
    }
    em {
        font-style: italic;
    }
    svg {
        max-width: 20px;
    }
}

.idioma-original {
    letter-spacing: 0.8px;
    p {
        color: rgba($color: $colorInterfaz, $alpha: 0.8);
        font-family: $futura;
        font-size: 0.75em;
    }
}

.switch {
    display: inline-block;
    height: 0.85em;
    padding-bottom: 3px;
    position: relative;
    width: 30px;
}

.switch {
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    &::before {
        position: absolute;
        content: "";
        height: 11px;
        width: 11px;
        left: 3px;
        bottom: 3px;
        background-color: #fff;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
}

input {
    &:checked {
        + .slider {
            background-color: #c5d0d9;
            &::before {
                background-color: $colorInterfaz;
                transform: translateX(13px);
            }
        }
    }
    &:focus {
        + .slider {
            box-shadow: 0 0 1px #c5d0d9;
        }
    }
}

.round {
    border-radius: 34px;
    &::before {
        border-radius: 50%;
    }
}

.imagen-poema-container {
    width: calc(100% - 40px);
}

.imagen-poema {
    display: block;
    border: 1px solid rgba($color: $color-gris, $alpha: 0.4);
    max-width: 617px;
}

.video-poema {
    border: none;
}

.ReactModal__Overlay {
    z-index: 100000000 !important;
}

.texto-contenido {
    overflow-y: auto;
    p:not(:last-child) {
        margin-bottom: 1rem;
    }
    .bold {
        font-weight: 600 !important;
    }
    .indentado {
        padding-left: 15px;
    }
    .indentado2 {
        padding-left: 60px;
    }
}

.texto-poema {
    position: relative;
    span {
        cursor: pointer;
    }
    .texto-contenido {
        margin-top: 3rem;
    }
    .content-buttons--change-font-size {
        top: 0.3rem;
    }
}

.texto-particion {
    cursor: inherit !important;
    color: $colorTextos !important;
    display: block;
}

.contenedor-resaltado-titulo {
    color: $colorInterfaz;
    font-family: $bookman;
    font-size: 1.5em;
    line-height: 1em;
    .titulo-poema {
        font-family: $bookman;
        font-style: italic;
        font-weight: 500 !important;
        span {
            color: inherit;
            cursor: pointer;
            font-weight: 500 !important;
        }
    }
}

.fslightbox-container {
    opacity: 1;
}

.tooltip-citas {
    border: 2px solid rgba($color: #000, $alpha: 0.6) !important;
    border-radius: 10px !important;
    color: $colorTextos !important;
    font-family: $garamond !important;
    font-size: 0.9em !important;
    max-height: 200px;
    max-width: 300px;
    opacity: 1 !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.prologos {
    .contenedor-contenido {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 1.5rem !important;
        width: 100%;
    }
}

@media only screen and (min-width: 992px) {
    .contenedor-imagen-contenido {
        width: calc(100% - 550px);
        padding-right: 1.5em;
    }
    .contenido-info {
        width: 550px;
        .content-buttons--change-font-size {
            margin-left: 0.8rem;
            margin-right: 0;
            right: 0;
        }
    }
    .prologos {
        .contenedor-imagen-contenido {
            max-width: 400px;
        }
        .contenido-info {
            width: calc(100% - 400px);
        }
        .imagen-poema{
            width: 400px;
            max-width: 400px;
            margin: 0 auto;
        }
    }
    .contenedor-poema {
        margin-right: 0;
    }
    .info-ilustrador {
        text-align: end;
        transform: rotate(180deg);
        writing-mode: vertical-lr;
    }
    .titulo-seccion {
        left: 313px;
        position: absolute;
        top: 20px;
    }
}

@media only screen and (min-width: 1440px) {
    .prologos {
        .contenedor-imagen-contenido {
            max-width: 550px;
        }
        .contenido-info {
            width: calc(100% - 550px);
        }
        .imagen-poema{
            width: 550px;
            max-width: 550px;
            margin: 0 auto;
        }
    }
}
