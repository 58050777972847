@import "./variables.scss";

h2 {
    font-family: $futura;
}
p {
    font-family: $berkeley;
    font-size: 1em;
}
ul {
    list-style: none;
    padding: 0;
}

.componente-menu-continente {
    p {
        font-size: 1em;
    }
}

.num-poemas {
    color: $colorTextos !important;
    font-family: $futura;
    font-size: 0.76em;
    margin-left: 0.8rem;
    text-transform: none;
}

.item-poema {
    border-bottom: 1.5px solid rgba($color: $color-gris, $alpha: 0.7);
    padding: 1.25rem 1rem;
    span{
        color: inherit;
    }
    sup{
        display: none;
    }
}

.titulo-poema {
    font-family: $berkeley;
    font-style: italic;
    font-weight: 900 !important;
}

.columna-imagenes {
    height: 400px !important;
    position: relative;
}

.img-persona-volando {
    bottom: 195px;
    left: 70px;
    mix-blend-mode: darken;
    position: absolute;
}

.imagen-contiente {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 300px auto;
    height: 100%;
}

.africa {        
    .columna-imagenes {
        height: 350px !important;
    }
    .img-persona-volando {
        bottom: 38%;
        left: 30%;
        width: 75%;
    }
}

.america {
    .img-persona-volando {
        bottom: 33%;
        left: 23%;
        width: 60%;
    }
}

.asia {     
    .columna-imagenes {
        height: 250px !important;
    }
    .img-persona-volando {
        bottom: 28%;
        left: 10%;
        width: 80%;
    }
}

.europa {            
    .columna-imagenes {
        height: 320px !important;
    }
    .img-persona-volando {
        bottom: 28%;
        left: -10%;
        width: 80%;
    }
}

.oceania {           
    .columna-imagenes {
        height: 350px !important;
    }
    .img-persona-volando {
        bottom: 25%;
        left: 0%;
        width: 90%;
    }
}


@media only screen and (min-width: 576px) {    
    .columna-imagenes {
        height: 380px !important;
    }
    
    .img-persona-volando {
        width: 40% !important;
    }

    .africa {
        .img-persona-volando {
            bottom: 45%;
        }
    }

    .america {
        .img-persona-volando {
            left: 30%;
        }
    }

    .asia {
        .img-persona-volando {
            left: 30%;
            width: 45% !important;
        }
    }

    .europa {
        .columna-imagenes {
            height: 250px !important;
        }
        .img-persona-volando {
            left: 20%;
        }
    }

    .oceania {        
        .columna-imagenes {
            height: 300px !important;
        }
        .img-persona-volando {
            bottom: 40%;
            left: 30%;
        }
    }
}

@media only screen and (min-width: 768px) {    
    .columna-imagenes {
        height: 480px !important;
    }
    
    .imagen-contiente {
        background-size: 400px auto;
    }

    .africa {
        .columna-imagenes {
            height: 480px !important;
        }
    }

    .america {
        .imagen-contiente{
            background-size: contain;
        }
    }

    .asia {        
        .columna-imagenes {
            height: 350px !important;
        }
    }

    .europa {
        .columna-imagenes {
            height: 350px !important;
        }
    }

    .oceania {        
        .columna-imagenes {
            height: 400px !important;
        }
    }
}


@media only screen and (min-width: 992px) {
    .item-poema {
        padding: 0.5rem 0;
        transition: padding 0.2s;
        &:hover {
            border-bottom-width: 2px;
        }
    }

    .lista-poemas {
        overflow: auto;
        li {
            max-width: 1180px;
        }
    }

    .columna-textos {
        width: calc(100% - 350px);
    }

    .columna-imagenes {
        align-self: flex-end;
        height: 100% !important;
        position: relative;
        width: 350px;
    }

    .imagen-contiente {
        background-size: contain;
    }

    .africa {
        .img-persona-volando {
            bottom: 38% !important;
            left: 30% !important;
            width: 75% !important;
        }
    }

    .america {
        .img-persona-volando {
            bottom: 21% !important;
            left: 20% !important;
            width: 60% !important;
        }
    }

    .asia {
        .img-persona-volando {
            bottom: 28% !important;
            left: 10% !important;
            width: 80% !important;
        }
    }

    .europa {
        .img-persona-volando {
            bottom: 28% !important;
            left: -10% !important;
            width: 80% !important;
        }
    }

    .oceania {
        .img-persona-volando {
            bottom: 35% !important;
            left: 10% !important;
            width: 90% !important;
        }
    }
}
