@import "variables";
html,
body {
    font-weight: 300;
}
body {
    background-color: #fff;
    font-size: 20px;
    line-height: 1.5;
    overflow-x: hidden;
}

img{
  image-rendering: -webkit-optimize-contrast;
}


a {
    color: $colorTextos;
    &:visited {
        text-decoration: none;
    }
    &:hover {
        color: $colorTextos;
        text-decoration: none;
    }
    &:active {
        text-decoration: none;
    }
    &:link {
        text-decoration: none;
    }
}

h1 {
    font-size: 2.2em;
}

h1,
h2,
h4 {
    font-weight: 700;
    margin-bottom: 0.5rem;
}
h2 {
  font-size: 1.4em;
}
h3 {
    font-size: 1em;
    margin: 1rem 0 1rem 0;
}
h4 {
    font-size: 1em;
}

p{
    font-family: $berkeley;
    font-weight: 500;
    margin-bottom: 0;
}

span{
    font-weight: 600;
}