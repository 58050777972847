@import "./variables.scss";

* {
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
    overflow-y: auto;
}

.africa {
    h2 {
        color: $africa;
    }
    span {
        color: $africa;
    }
    .continente-activo {
        color: $africa;
    }
    .item-continente-header {
        &:hover {
            p {
                color: $africa;
            }
        }
    }
    .item-continente-movil {
        background-color: $africa;
    }
    .item-poema {
        &:hover {
            background-color: $africa;
        }
    }
    .tooltip-citas {
        background-color: $africa;
        &::after {
            border: none !important;
        }
    }
}

.america {
    h2 {
        color: $america;
    }
    span {
        color: $america;
    }
    .continente-activo {
        color: $america;
    }
    .item-continente-header {
        &:hover {
            p {
                color: $america;
            }
        }
    }
    .item-continente-movil {
        background-color: $america;
    }
    .item-poema {
        &:hover {
            background-color: $america;
        }
    }
    .tooltip-citas {
        background-color: $america;
        &::after {
            border: none !important;
        }
    }
}

.asia {
    h2 {
        color: $asia;
    }
    span {
        color: $asia;
    }
    .continente-activo {
        color: $asia;
    }
    .item-continente-header {
        &:hover {
            p {
                color: $asia;
            }
        }
    }
    .item-continente-movil {
        background-color: $asia;
    }
    .item-poema {
        &:hover {
            background-color: $asia;
        }
    }
    .tooltip-citas {
        background-color: $asia;
        &::after {
            border: none !important;
        }
    }
}

.europa {
    h2 {
        color: $europa;
    }
    span {
        color: $europa;
    }
    .continente-activo {
        color: $europa;
    }
    .item-continente-header {
        &:hover {
            p {
                color: $europa;
            }
        }
    }
    .item-continente-movil {
        background-color: $europa;
    }
    .item-poema {
        &:hover {
            background-color: $europa;
        }
    }
    .tooltip-citas {
        background-color: $europa;
        &::after {
            border: none !important;
        }
    }
}

.oceania {
    h2 {
        color: $oceania;
    }
    span {
        color: $oceania;
    }
    .continente-activo {
        color: $oceania;
    }
    .item-continente-header {
        &:hover {
            p {
                color: $oceania;
            }
        }
    }
    .item-continente-movil {
        background-color: $oceania;
    }
    .item-poema {
        &:hover {
            background-color: $oceania;
        }
    }
    .tooltip-citas {
        background-color: $oceania;
        &::after {
            border: none !important;
        }
    }
}

.prologos {
    a{
        color: $prologo;
    }
    h2 {
        color: $prologo;
    }
    .contenedor-resaltado-titulo {
        color: $prologo;
    }
    .continente-activo {
        color: $prologo !important;
    }
}

.content-buttons--change-font-size {
    position: absolute;
    top: -3rem;
    button {
        background-color: $colorInterfaz;
        border: none;
        border-radius: 50%;
        color: #fff;
        font-weight: 500;
        height: 35px;
        margin-right: 0.8rem;
        width: 35px;
    }
    p {
        font-family: $futura !important;
        padding-left: 0.1rem;
        text-align: center;
    }
}

@media only screen and (min-width: 992px) {
    html, body {
        overflow: hidden;
    }
}
