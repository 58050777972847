@import "./variables.scss";

.menu-principal {
    background-color: #fff;
    position: fixed;
    z-index: 10000;
}

.opciones-contientes {
    position: relative;
}

.opciones-idioma {
    a {
        &:not(:last-child) {
            margin-right: 1em;
        }
    }
    p {
        color: $color-gris;
        font-family: $futura;
        font-size: 0.8em;
        font-weight: 500;
        letter-spacing: 1px;
    }
}

.btn-home {
    background-image: url(../assets/img/icono_home.svg);
    background-repeat: no-repeat;
    background-size: contain;
    color: $color-gris;
    height: 30px;
    padding-left: 40px;
    width: 30px;
    padding-top: 4px;
    font-size: 0.85em;
    letter-spacing: 1px;
}

.header-menu-continentes {
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    padding: 0.2em 0 0.2em 5em;
    position: relative;
    h2 {
        color: $colorTextos;
        letter-spacing: 0.4px;
        margin-bottom: 0;
        text-decoration: underline;
        text-decoration-color: $color-gris;
        @extend .estilos-menu-continentes;
    }
}

.nube-menu {
    background-image: url("../assets/img/icono_nube.svg");
    bottom: -20px;
    height: 35px;
    left: 0;
    width: 6rem;
    z-index: 100;
    @extend .fondo;
    cursor: pointer;
}

.encabezado-menu-activa {
    &::after {
        background-image: url("../assets/img/flecha_arriba.svg");
    }
}

.idioma-activo {
    p {
        color: #000;
    }
}

.lista-contientes {
    line-height: 1.7em;
    margin-top: -0.5rem;
    z-index: -1;
}

.menu-movil {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;    
    font-family: $futura;
    height: 100vh;
    left: -480px;
    max-width: 480px;
    position: fixed;
    top: 0;
    transition: all 0.3s ease-in;
    width: 80%;
    z-index: 1000000;
    p {
        font-family: $futura;
        font-size: 0.8em;
    }
    .item-continente-header{
        font-weight: 600;
    }
}

.cerrar-menu{
    background-image: url("../assets/img/close.svg");
    cursor: pointer;
    height: 20px;
    right: 0;
    width: 20px;
    @extend .fondo;
}

.fondo{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    text-indent: -180px;
    top: 0;
}

.mostart-menu {
    left: 0;
}

@media only screen and (min-width: 992px) {

    .btn-home{
      padding-top: 0px;
      padding-left: 0px;
    }

    .lista-contientes {
        background-image: url("../assets/img/lineas_icono_contenido.png");
        background-position: 1.4rem 0;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 2.5rem 0 0 6.3rem;
        position: absolute;
        a {
            color: $color-gris;
            font-size: 1.3em;
            @extend .estilos-menu-continentes;
        }
    }

    .menu-principal {
        background-color: transparent;
    }

    .nube-menu {
        height: 100%;
        position: absolute;
    }

    .estilos-menu-continentes {
        font-family: $futura;
        font-weight: 700;
    }
}
