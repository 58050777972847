@import "../variables.scss";

.link-cambiar-poema{
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
    overflow: hidden;
    text-indent: -1000px;
    width: 20px;
}

.boton-anterior-poema{
    background-image: url("../../assets/img/flecha_anterior.svg");
}

.boton-siguiente-poema{
    background-image: url("../../assets/img/flecha_siguiente.svg");
}

@media only screen and (min-width: 992px) {
    .cambiar-poema {
        padding: 0.65em ;
        width: calc(100% - 550px);
        a {
            align-items: center;
            display: flex;
            justify-content: center;
        }
        p{
            font-family: $bookman !important;
            font-size: 0.75em !important;
        }
    }
    .prologos{
        .cambiar-poema {
            width: calc(100% - 600px) !important;
        }        
        .contenedor-cambiar-contenido {        
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 1.5rem !important;
            width: 100%;
        }
    }
}
