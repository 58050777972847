@import "../variables.scss";

.contenedor-menu-continentes {
    width: 2209.4px;
    height: 1151.3px;
}

.link-prologo{
    bottom: 0;
    position: absolute;
    right: 0;
    h2{
        margin-bottom: 0;
        padding: 1.2rem 0;
    }
}

.st0 {
    fill: #a2bcd0;
}
.st1 {
    fill: #dae5ed;
}
.st2 {
    fill: #a3bcd0;
}
.st3 {
    fill: #a2bdd1;
}
.st4 {
    fill: #a3bdd2;
}
.st5 {
    fill: #a3bdd1;
}
.st6 {
    fill: #a3bed1;
}
.st7 {
    fill: #a5bfd2;
}
.st8 {
    fill: #aec5d7;
}
.st9 {
    fill: #adbcce;
}
.st10 {
    fill: #abbccf;
}
.st11 {
    fill: #c1bacb;
}
.st12 {
    fill: #b0bccd;
}
.st13 {
    fill: #bed1df;
}
.st14 {
    fill: #b2b8cc;
}
.st15 {
    fill: #bcbecc;
}
.st16 {
    fill: #b0bbce;
}
.st17 {
    fill: #b3c9da;
}
.st18 {
    fill: #f69fa8;
}
.st19 {
    fill: #f2a5b0;
}
.st20 {
    fill: #f1a3ad;
}
.st21 {
    fill: #f2a7b0;
}
.st22 {
    fill: #ebabb7;
}
.st23 {
    fill: #eca3a9;
}
.st24 {
    fill: #f6a2ab;
}
.st25 {
    fill: #f6a0a9;
}
.st26 {
    fill: #f6a1aa;
}
.st27 {
    fill: #b29bbc;
}
.st28 {
    fill: #e0d8e4;
}
.st29 {
    fill: #b59dbd;
}
.st30 {
    fill: #b39dbd;
}
.st31 {
    fill: #adb2c6;
}
.st32 {
    fill: #b59dbe;
}
.st33 {
    fill: #b19ebe;
}
.st34 {
    fill: #bea9c6;
}
.st35 {
    fill: #b1a2c0;
}
.st36 {
    fill: #b0a4c0;
}
.st37 {
    fill: #aeaac2;
}
.st38 {
    fill: #aea8c2;
}
.st39 {
    fill: #abb6ca;
}
.st40 {
    fill: #f3c38c;
}
.st41 {
    fill: #f2bc7d;
}
.st42 {
    fill: #f2bd80;
}
.st43 {
    fill: #f3be81;
}
.st44 {
    fill: #f2be81;
}
.st45 {
    fill: #f3c188;
}
.st46 {
    fill: #f4c48e;
}
.st47 {
    fill: #f6cb9b;
}
.st48 {
    fill: #f5c690;
}
.st49 {
    fill: #f2bf83;
}
.st50 {
    fill: #f3c38b;
}
.st51 {
    fill: #f3c289;
}
.st52 {
    fill: #f6cd9f;
}
.st53 {
    fill: #f5c692;
}
.st54 {
    fill: #f3c084;
}
.st55 {
    fill: #f6cfa2;
}
.st56 {
    fill: #f7d2a6;
}
.st57 {
    fill: #f2bc7f;
}
.st58 {
    fill: #f6cfa4;
}
.st59 {
    fill: #f5c58f;
}
.st60 {
    fill: #f6cda0;
}
.st61 {
    fill: #f6ca98;
}
.st62 {
    fill: #f6cfa3;
}
.st63 {
    fill: #f3c085;
}
.st64 {
    fill: #f5c794;
}
.st65 {
    fill: #f3c086;
}
.st66 {
    fill: #f3bf84;
}
.st67 {
    fill: #f3bf83;
}
.st68 {
    fill: #f5c693;
}
.st69 {
    fill: #f4c186;
}
.st70 {
    fill: #f6cea2;
}
.st71 {
    fill: #f6cb99;
}
.st72 {
    fill: #f4c590;
}
.st73 {
    fill: #f6d0a6;
}
.st74 {
    fill: #f4c691;
}
.st75 {
    fill: #f5c793;
}
.st76 {
    fill: #f6cea1;
}
.st77 {
    fill: #f4c48f;
}
.st78 {
    fill: #f6ca97;
}
.st79 {
    fill: #f6c996;
}
.st80 {
    fill: #f3c28b;
}
.st81 {
    fill: #f3c189;
}
.st82 {
    fill: #f7d1a8;
}
.st83 {
    fill: #f5c995;
}
.st84 {
    fill: #f6cd9e;
}
.st85 {
    fill: #f6d0a4;
}
.st86 {
    fill: #f7cb9d;
}
.st87 {
    fill: #f6d2ab;
}
.st88 {
    fill: #f6cc9d;
}
.st89 {
    fill: #f6d1a9;
}
.st90 {
    fill: #b7cec8;
}
.st91 {
    fill: #b8d0ca;
}
.st92 {
    fill: #b7cec9;
}
.st93 {
    fill: #b9cfca;
}
.st94 {
    fill: #b9cec9;
}
.st95 {
    fill: #d8e4e0;
}
.st96 {
    fill: #bbd0cb;
}
.st97 {
    fill: #dbe5e2;
}
.st98 {
    fill: #f3f6f6;
}
.st99 {
    fill: #d8e5e0;
}
.st100 {
    fill: #cdded9;
}
.st101 {
    fill: #e2ecea;
}
.st102 {
    fill: #d6e3df;
}
.st103 {
    fill: #e2ece8;
}
.st104 {
    fill: #ccdbd7;
}
.st105 {
    fill: #d0dfdb;
}
.st106 {
    fill: #e3ebe9;
}
.st107 {
    fill: #ebbabb;
}
.st108 {
    fill: #bfd2cd;
}
.st109 {
    fill: #d1e0dc;
}
.st110 {
    fill: #f0f5f5;
}
.st111 {
    fill: #e2eae8;
}
.st112 {
    fill: #b5bccc;
}
.st113 {
    fill: #c3d7d2;
}
.st114 {
    fill: #f2f5f6;
}
.st115 {
    fill: #c2d6d1;
}
.st116 {
    fill: #f4bfbe;
}
.st117 {
    fill: #cfdeda;
}
.st118 {
    fill: #e7c0c3;
}
.st119 {
    fill: #e5ecea;
}
.st120 {
    fill: #bed3ce;
}
.st121 {
    fill: #c0d5ce;
}
.st122 {
    fill: #c2d5d0;
}
.st123 {
    fill: #c5d7d2;
}
.st124 {
    fill: #c6d8d4;
}
.st125 {
    fill: #cededa;
}
.st126 {
    fill: #c5bac7;
}
.st127 {
    fill: #cbdcd6;
}
.st128 {
    fill: #c9d9d6;
}
.st129 {
    fill: #c7d9d3;
}
.st130 {
    fill: #cbdad6;
}
.st131 {
    fill: #c6d8d3;
}
.st132 {
    fill: #cadad5;
}
.st133 {
    fill: #c6d8d2;
}
.st134 {
    fill: #dee8e5;
}
.st135 {
    fill: #d2e1dd;
}
.st136 {
    fill: #d3e0dd;
}
.st137 {
    fill: #f4f7f6;
}
.st138 {
    fill: #cbdcd8;
}