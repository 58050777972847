@import "variables";

.portada-es{
    h1{
        background: url('../assets/img/portada/titulo.png') center/contain no-repeat;
    }
    
    h2{
        background: url('../assets/img/portada/subtitulo.png')left/100% no-repeat;
    }
}

.portada-en{
    h1{
        background: url('../assets/img/portada/titulo.png') center/contain no-repeat;
    }
    
    h2{
        background: url('../assets/img/portada/subtitulo.png')left/100% no-repeat;
    }
}

.contenedorPortada{
    width: 100%;
    height: 100vh;
    background: url('../assets/img/portada/fondo.jpg') center/cover no-repeat;

    h1{ height: 60px;}
    h2{ height: 30px;}

    h1,
    h2{
        text-indent: -1000px;
        overflow: hidden;
    }
}

.contenedor-centrado{
    background: url('../assets/img/portada/mujer-arriba.png') 0 15% /contain no-repeat, url('../assets/img/portada/mujer-abajo.png')  0 80% /contain no-repeat, url('../assets/img/portada/mapa.png') center/cover no-repeat;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    position: relative;
}

.texto-centrado{
    width: 90%;
    height: 20%;
}

.leer{
    background: url('../assets/img/flecha_portada.svg') center bottom / 30px auto no-repeat;
    bottom: 10px;
    color: $colorInterfaz;
    font-family: $futura; 
    font-size: 2em;
    font-weight: bold;
    left: 0;
    margin: auto;
    padding-bottom: 10px;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    width: 80px;
    &:hover{
      color: $colorInterfaz;
    }
}

@media only screen and (min-width: 526px) {
    .contenedor-centrado{
        background: url('../assets/img/portada/mujer-arriba.png') 0 5% /contain no-repeat, url('../assets/img/portada/mujer-abajo.png')  0 80% /contain no-repeat, url('../assets/img/portada/mapa.png') center/cover no-repeat;
    }
    .contenedorPortada{
        h1{height: 100px;}

        h2{
            height: 50px;
            background-size:75%;
        }
    }

    .texto-centrado{height: 30%;}
}

@media only screen and (min-width: 992px) {

    .contenedorPortada{
        h1{height: 150px;}
    
        h2{
            background-size:70%;
            height: 50px;
        }
    }
    .contenedor-centrado{
        background: url('../assets/img/portada/mujeres.png') center/cover no-repeat, url('../assets/img/portada/mapa.png') center/cover no-repeat;
        background-size: 100%, 100% ;          
    }

    .texto-centrado{width: 85%;}
}


@media only screen and (min-width: 1440px) {
    .contenedorPortada{
        h1{height: 200px;}
        h2{height: 60px;}
    }
}
